import React from 'react'
import { navigate } from 'gatsby-link'
import { Link } from 'gatsby'
//import Envelope from '../../images/envelope.svg'
const API_KEY = process.env.MG_API_KEY

const formData = require('form-data');
const Mailgun = require('mailgun.js');
const mailgun = new Mailgun(formData);
const client = mailgun.client({username: 'api', key: API_KEY, url:"https://api.eu.mailgun.net"});

// console.log(client)

const htmldata = `
<body
  leftmargin="0"
  marginwidth="0"
  topmargin="0"
  marginheight="0"
  offset="0"
  style="background-color: #e8d3d0; padding: 0; text-align: center"
  bgcolor="#e8d3d0"
>
  <table
    width="100%"
    id="outer_wrapper"
    style="background-color: #e8d3d0"
    bgcolor="#e8d3d0"
  >
    <tbody>
      <tr>
        <td>
          <!-- Deliberately empty to support consistent sizing and layout across multiple email clients. -->
        </td>
        <td width="600">
          <div
            id="wrapper"
            dir="ltr"
            style="
              margin: 0 auto;
              padding: 70px 0;
              width: 100%;
              max-width: 600px;
              -webkit-text-size-adjust: none;
            "
            width="100%"
          >
            <table
              border="0"
              cellpadding="0"
              cellspacing="0"
              height="100%"
              width="100%"
            >
              <tbody>
                <tr>
                  <td align="center" valign="top">
                    <div id="template_header_image">
                      <p style="margin-top: 0">
                        <img
                          src="https://admin.shroom4you.com/wp-content/uploads/2023/05/email_small_logo.png"
                          alt="Shroom Drink"
                          style="
                            border: none;
                            display: inline-block;
                            font-size: 14px;
                            font-weight: bold;
                            height: auto;
                            outline: none;
                            text-decoration: none;
                            text-transform: capitalize;
                            vertical-align: middle;
                            max-width: 75%;
                            margin-left: 0;
                            margin-right: 0;
                          "
                          border="0"
                        />
                      </p>
                    </div>
                    <table
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      id="template_container"
                      style="
                        background-color: #fff;
                        border: 1px solid #d1bebb;
                        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
                        border-radius: 3px;
                      "
                      bgcolor="#fff"
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top">
                            <!-- Header -->
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                              id="template_header"
                              style="
                                background-color: #281d47;
                                color: #fff;
                                border-bottom: 0;
                                font-weight: bold;
                                line-height: 100%;
                                vertical-align: middle;
                                font-family: 'Helvetica Neue', Helvetica, Roboto,
                                  Arial, sans-serif;
                                border-radius: 3px 3px 0 0;
                              "
                              bgcolor="#281d47"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    id="header_wrapper"
                                    style="padding: 36px 48px; display: block"
                                  >
                                    <h1
                                      style="
                                        font-family: 'Helvetica Neue', Helvetica,
                                          Roboto, Arial, sans-serif;
                                        font-size: 30px;
                                        font-weight: 300;
                                        line-height: 150%;
                                        margin: 0;
                                        text-align: left;
                                        text-shadow: 0 1px 0 #534a6c;
                                        color: #fff;
                                        background-color: inherit;
                                      "
                                      bgcolor="inherit"
                                    >
                                     Thank you for getting in touch!
                                    </h1>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <!-- End Header -->
                          </td>
                        </tr>
                        <tr>
                          <td align="center" valign="top">
                            <!-- Body -->
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                              id="template_body"
                            >
                              <tbody>
                                <tr>
                                  <td>
                                      <img
                                        alt="contact"
                                        title="contact"
                                        width="150"
                                        height="auto"
                                        src="https://admin.shroom4you.com/wp-content/uploads/2024/03/kontakt_1.gif"
                                      />
                                  </td>
                                  <td style="background-color: whitesmoke">
                                    <h2
                                      style="
                                        color: #281d47;
                                        display: block;
                                        font-family: 'Helvetica Neue', Helvetica,
                                          Roboto, Arial, sans-serif;
                                        font-size: 18px;
                                        font-weight: bold;
                                        line-height: 130%;
                                        padding: 16px;
                                        text-align: left;
                                      "
                                    >
                                      Our team will get back to You once we read your message.
                                    </h2>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    valign="top"
                                    id="body_content"
                                    style="background-color: #fff"
                                    bgcolor="#fff"
                                  >
                                    <!-- Content -->
                                    <table
                                      border="0"
                                      cellpadding="20"
                                      cellspacing="0"
                                      width="100%"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            valign="top"
                                            style="padding: 32px 48px 32px"
                                          >
                                            <div
                                              id="body_content_inner"
                                              style="
                                                color: #636363;
                                                font-family: 'Helvetica Neue',
                                                  Helvetica, Roboto, Arial,
                                                  sans-serif;
                                                font-size: 14px;
                                                line-height: 150%;
                                                text-align: left;
                                              "
                                              align="left"
                                            >
                                              <h2
                                                style="
                                                  color: #281d47;
                                                  display: block;
                                                  font-family: 'Helvetica Neue',
                                                    Helvetica, Roboto, Arial,
                                                    sans-serif;
                                                  font-size: 18px;
                                                  font-weight: bold;
                                                  line-height: 130%;
                                                  margin: 0 0 18px;
                                                  text-align: left;
                                                "
                                              >
                                                Thank you for choosing Shroom Drink!
                                              </h2>
                                              <p style="text-align: left">
                                                While you wait, why not visit
                                                our
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://shroom4you.com/blog/"
                                                  class="link"
                                                  style="
                                                    font-weight: normal;
                                                    text-decoration: underline;
                                                    color: #281d47;
                                                  "
                                                  >blog</a
                                                >
                                                or learn more about
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  class="link"
                                                  style="
                                                    font-weight: normal;
                                                    text-decoration: underline;
                                                    color: #281d47;
                                                  "
                                                  href="https://shop.shroom4you.com"
                                                >
                                                our drinks and
                                                accessories.</a>
                                              </p>
                                              <p
                                                style="
                                                  text-align: center;
                                                  font-size: 10px;
                                                  margin-top: 10px;
                                                  margin-bottom: 5px;
                                                "
                                              >
                                                We hope you like our content. If
                                                not here you can
                                                <a
                                                  target="_blank"
                                                  class="link"
                                                  style="
                                                    font-weight: normal;
                                                    text-decoration: underline;
                                                    color: #281d47;
                                                  "
                                                  rel="noopener noreferrer"
                                                  href="https://shop.shroom4you.com/contact/"
                                                  >get in touch.</a
                                                >
                                              </p>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <div
                                      style="
                                        background-color: #281d47;
                                        text-align: center;
                                        padding: 15px;
                                        margin: 0;
                                      "
                                    >
                                      <p>
                                        <a
                                          href="https://www.instagram.com/shroom4you"
                                          aria-label="instagram"
                                          target="_blank"
                                          rel="noreferrer"
                                          >
                                          <img width="35" src="https://admin.shroom4you.com/wp-content/uploads/2024/03/insta.png" /></a>
                                        &nbsp;&nbsp;
                                        <a
                                          href="https://www.linkedin.com/company/shroom4you/"
                                          aria-label="linkedin"
                                          target="_blank"
                                          rel="noreferrer"
                                          ><img width="35" src="https://admin.shroom4you.com/wp-content/uploads/2024/03/linkedin.png" /></a>
                                        &nbsp;&nbsp;
                                        <a
                                          href="https://www.facebook.com/shroom4you"
                                          aria-label="facebook"
                                          target="_blank"
                                          rel="noreferrer"
                                          ><img width="35" src="https://admin.shroom4you.com/wp-content/uploads/2024/03/face.png" /></a>
                                      </p>
                                    </div>
                                    <!-- End Content -->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <!-- End Body -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td align="center" valign="top">
                    <!-- Footer -->
                    <table
                      border="0"
                      cellpadding="10"
                      cellspacing="0"
                      width="100%"
                      id="template_footer"
                    >
                      <tbody>
                        <tr>
                          <td
                            valign="top"
                            style="padding: 0; border-radius: 6px"
                          >
                            <table
                              border="0"
                              cellpadding="10"
                              cellspacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    colspan="2"
                                    valign="middle"
                                    id="credit"
                                    style="
                                      border-radius: 6px;
                                      border: 0;
                                      color: #111111;
                                      font-family: 'Helvetica Neue', Helvetica,
                                        Roboto, Arial, sans-serif;
                                      font-size: 12px;
                                      line-height: 150%;
                                      text-align: center;
                                      padding: 24px 0;
                                    "
                                    align="center"
                                  >
                                    <p style="margin: 0 0 16px">
                                      Shroom Drink<br> Learn more on
                                      <a
                                        href="https://shroom4you.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style="
                                          color: #281d47;
                                          font-weight: normal;
                                          text-decoration: underline;
                                        "
                                        >shroom4you.com</a
                                      >
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- End Footer -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
        <td>
          <!-- Deliberately empty to support consistent sizing and layout across multiple email clients. -->
        </td>
      </tr>
    </tbody>
  </table>
</body>
`




function encode(data) {
  const formData = new FormData()


  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData

}

export default class FormMsg extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] })
  }

  handleSubmit = e => {


    let fileinput = document.getElementById('fileinput');
    let file = fileinput.files[0];

    let submitBtn = document.querySelector('button[type="submit"]');
    submitBtn.disabled = true;
    setTimeout(function() {
      submitBtn.disabled = false;
    }, 2000);

    if (file === undefined){
      e.preventDefault()
      const form = e.target
      fetch('/', {
        method: 'POST',
        body: encode({
          'form-name': form.getAttribute('name'),
          ...this.state,
        }),
      })
      .then(() => {

        const messageData = {
          from: 'Shroom Drinks <shop@shroom4you.com>',
          to: document.getElementById('emailx').value,
          subject: 'Thank you for getting in touch!',
          html: htmldata
        };
        //console.log(client)

        client.messages.create('shop.shroom4you.com', messageData)
         .then((res) => {
           console.log(res);
         })
         .catch((err) => {
           console.error(err);
         });


        navigate('/thank-you/')
      })
        .catch(error => alert(error))
    }
    else if (file.size<1000000){
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
    .then(() => {

      const messageData = {
        from: 'Shroom Drinks <shop@shroom4you.com>',
        to: document.getElementById('emailx').value,
        subject: 'Thank you for getting in touch!',
        html: htmldata
      };
      //console.log(client)

      client.messages.create('shop.shroom4you.com', messageData)
       .then((res) => {
         console.log(res);
       })
       .catch((err) => {
         console.error(err);
       });


      navigate('/thank-you/')
    })
      .catch(error => alert(error))
  } else {
    alert('The file is too big. Please try again with a smaller file.');

  }
}
  render() {
    return (
      <>





        {/*message*/}

        <form
          name="contact-form"
          method="post"
          action="/thank-you/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
          style={{textAlign:'left',padding:'7%',borderRadius:'20px'}}

        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="file-upload" />
          <div hidden>
            <label>
              Don’t fill this out:{' '}

              <input name="bot-field"
              onChange={this.handleChange}
              />
            </label>
          </div>
          <h3 className="text-dark spec-h">Contact form</h3>

          <div className="field">
            <label className="label form-label mb-3" htmlFor={'namex'}>
              Name<sup>*</sup>:
            </label>
            <div className="control">
              <input
                className="input form-control mb-4"
                type={'text'}
                name={'Name'}
                onChange={this.handleChange}
                id={'namex'}
                required={true}
              />
            </div>
          </div>

          <div className="field">
            <label className="label form-label mb-3" htmlFor={'emailx'}>
              Email<sup>*</sup>:
            </label>
            <div className="control">
              <input
                className="input form-control mb-4"
                type={'email'}
                name={'email'}
                onChange={this.handleChange}
                id={'emailx'}
                required={true}
              />
            </div>
          </div>


          <div className="field">
            <label className="label form-label mb-3" htmlFor={'phonex'}>
              Phone number:
            </label>
            <div className="control">
              <input
                className="input form-control mb-4"
                type={'tel'}
                name={'phone'}
                onChange={this.handleChange}
                id={'phonex'}
                required={false}
              />
            </div>
          </div>


          <div className="field">
            <label className="label form-label mb-3" htmlFor={'msgx'}>
              Message<sup>*</sup>:
            </label>
            <div className="control">
              <textarea
                className="input form-control mb-4"
                type={'text'}
                name={'message'}
                onChange={this.handleChange}
                id={'msgx'}
                required={true}
                style={{height:'100px'}}
              ></textarea>
            </div>
          </div>
          <br />
          <div className="field">
            <div className="file">
              <label className="file-label">
                <input
                  className="btn btn-outline-primary"
                  type="file"
                  name="attachment"
                  onChange={this.handleAttachment}
                  style={{height:'50px',width:'100%'}}
                  id ="fileinput"
                />
              </label>
            </div>
            <label htmlFor="fileinput" style={{width:'100%',padding:'2px',marginLeft:'0px',fontWeight:'600',fontSize:'13px'}}> <sup>*</sup>Maximum file size is <b>1mb</b>.</label>
          </div>

          <div className="form-check mt-2">
          <label>
          <input onChange={this.handleChange} required={true} value="yes" className="form-check-input" name="gdpr" type="checkbox"/>
          <span>I agree to receive messages for direct marketing purposes from <b>Shroom sp. z o.o.</b> containing commercial information about products. I agree to processing my personal data through the contact form in accordance with our <Link to="/privacy-policy/">privacy policy</Link></span></label>
          </div>
          <br />
          <div className="field has-text-centered">
            <button id="submit" type="submit" className="btn btn-primary" onClick={showFileSize}>
              Send message{/*&nbsp;&nbsp;<i className="contact-icon"><img src={Envelope} id="send" alt="icon" width="auto" style={{maxWidth:'20px',minWidth:'15px'}} /></i>*/}
            </button>
          </div>
        </form>








      </>
    )
  }
}
function showFileSize() {
let fileinput = document.getElementById('fileinput');
let file = fileinput.files[0];

if (file !== undefined){
console.log(file.size);
}
console.log('message sent!')
}
